// Construct meta properties for Open Graph on resource pages

import { MetaInfo } from 'vue-meta';
import { Context } from '@nuxt/types';
import { Inject } from '@nuxt/types/app';
import { Category } from '~/types/category';
import { Resource } from '~/types/resource';

interface MetaPropertiesCategoryOptions {
  title?: string; // Use the title supplied instead of inferring from the category
  description?: string; // Use the description supplied instead of inferring from the category
}

declare module '@nuxt/types' {
  interface Context {
    // TODO: More specific types for resource and category
    $metaPropertiesResource(resource: Resource): MetaInfo['meta'];
    $metaPropertiesCategory(
      category: Category,
      options?: MetaPropertiesCategoryOptions
    ): MetaInfo['meta'];
  }
}

export default ({ app, $config: { baseURL } }: Context, inject: Inject): void => {
  inject('metaPropertiesResource', (resource: Resource): MetaInfo['meta'] => {
    const title = resource.name || resource?.meta_title;
    const description =
      app.$truncateString(resource.excerpt, 160, true) || resource.meta_description;
    const properties = [
      { hid: 'title', name: 'title', content: title },
      { hid: 'description', name: 'description', content: description },
      {
        hid: 'og-type',
        property: 'og:type',
        content: resource.type === 'blog' ? 'article' : 'product',
      },
      { hid: 'og-title', property: 'og:title', content: title },
      { hid: 'og-description', property: 'og:description', content: description },
      { hid: 'og-updated_time', property: 'og:updated_time', content: resource.modified },
      {
        hid: 'og-image',
        property: 'og:image',
        content: resource.thumbnail ? resource.thumbnail : baseURL + '/images/share_image.png',
      },
      { hid: 'og-image-width', property: 'og:image:width', content: '600' },
      { hid: 'og-image-height', property: 'og:image:height', content: '400' },
      { hid: 'og-url', property: 'og:url', content: baseURL + resource.link },
      {
        hid: 'og-locale',
        property: 'og:locale',
        content: 'en_' + resource.country.toUpperCase(),
      },
      {
        hid: 'article-author',
        property: 'article:author',
        content: resource.author ? resource.author.display_name : '',
      },
      {
        hid: 'article-published_time',
        property: 'article:published_time',
        content: resource.created,
      },
      {
        hid: 'article-modified_time',
        property: 'article:modified_time',
        content: resource.modified,
      },
    ];

    // Add publisher - a bit hacky here
    const publishers = [
      { name: 'Teach Starter Publishing', link: 'https:/www.facebook.com/teachstarter' },
      { name: 'K-3 Teacher Resources', link: 'https://www.facebook.com/K3TeacherResources' },
      { name: 'Wingaru Kids', link: 'https://www.facebook.com/wingaru' },
    ];

    if (resource.publisher) {
      const publisher = publishers.find(publisher => publisher.name === resource.publisher?.name);
      properties.push({
        hid: 'article-publisher',
        property: 'article:publisher',
        content: publisher ? publisher.link : '',
      });
    }

    return properties;
  });

  inject(
    'metaPropertiesCategory',
    (category: Category, options?: MetaPropertiesCategoryOptions): MetaInfo['meta'] => {
      const title = options?.title || category.name + ' Teaching Resources';
      const description =
        options?.description ||
        category.meta_description ||
        app.$truncateString(category.description, 160, true);

      const properties = [
        { hid: 'title', name: 'title', content: title },
        { hid: 'description', name: 'description', content: description },
        { hid: 'og-type', property: 'og:type', content: 'article' },
        { hid: 'og-title', property: 'og:title', content: title },
        { hid: 'og-description', property: 'og:description', content: description },
        {
          hid: 'og-image',
          property: 'og:image:secure_url',
          content: category.header_image
            ? category.header_image
            : baseURL + '/images/share_image.png',
        },
        {
          hid: 'og-image',
          property: 'og:image',
          content: category.header_image
            ? category.header_image
            : baseURL + '/images/share_image.png',
        },
        { hid: 'og-image-width', property: 'og:image:width', content: '1200' },
        { hid: 'og-image-height', property: 'og:image:height', content: '630' },
        { hid: 'og-url', property: 'og:url', content: baseURL + category.link },
      ];

      return properties;
    }
  );
};
